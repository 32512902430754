import styled from 'styled-components'
import Logo from '../assets/logo.svg'
import { device } from '../devices'

const Wrap = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
`

const Container = styled.div`
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 0 1em;
`

const LogoWrap = styled.div`
  width: 200px;
  align-items: center;
  display: flex;
  padding-top: 1rem;
  padding-bottom: 1rem;
`

const Img = styled.img`
  max-width: 100%;
`

const Header = () => {
  return (
    <Wrap>
      <Container>
        <LogoWrap>
          <Img src={Logo} />
        </LogoWrap>
      </Container>
    </Wrap>
  )
}

export default Header
