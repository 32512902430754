import styled from 'styled-components'
import { colors } from '../theme'

import LinkButton from './LinkButton'

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 300px;
  padding: 2em;
`

const LogoWrap = styled.div`
  width: 110px;
  height: 110px;
  border-radius: 26px;
  background-color: ${props => props.color || props.theme?.logoBackground};
  display: flex;
  justify-content: center;
  align-items: center;
`

const Logo = styled.img`
  max-width: 100%;
  max-height: 100%;
  border-radius: 25px;
`

const TopSpace = styled.div`
  padding-top: 1rem;
`

const CardTitle = styled.p`
  font-weight: 500;
  font-size: 1rem;
  margin-bottom: 0;
  color: ${props => props.color || props.theme?.primaryTextColor};
`
const CardTags = styled.p`
  font-weight: 500;
  margin: 0;
  color: ${colors.darkGrey};
  font-size: 1rem;
`

const BodyText = styled.p`
  color: ${props => props.color || props.theme?.primaryTextColor};
  margin: 0;
  line-height: 150%;
`

const ProjectCard = (props) => {
  const {
    logo,
    title,
    desc,
    tags = [],
    link,
    linkText,
    buttonType
  } = props

  return (
    <Wrap>
      <LogoWrap>
        <Logo src={logo} alt={title} style={props} />
      </LogoWrap>
      <TopSpace />
      <CardTitle>{title}</CardTitle>
      <TopSpace />
      <CardTags>
        {tags && tags.map((item, i) => {
          return (i ? ' · ' : '') + item
        }
        )}
      </CardTags>
      <TopSpace />
      <BodyText>{desc}</BodyText>
      <TopSpace />
      <TopSpace style={{ flex: 1 }} />
      {(link || linkText) &&
        <LinkButton buttonType={buttonType} linkText={linkText} link={link} />}
    </Wrap>
  )
}

export default ProjectCard
