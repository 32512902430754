const translations = {
  translation: {
    lblbutton: 'Visit website',
    lblgithub: 'Visit our Github',
    introTitle: 'AI & Design for the future',
    lblCookie: 'We are using cookies to improve your experience.',
    introSubtitle: 'We love design that feels great. We love making your life easier. We don’t know what we’re doing, but we’re great at doing what we love.',
    introExtra: 'We only do fun projects. Gotta stay healthy.',
    projectsTitle: 'What we believe is fun¹.',
    projectsSubtitle: 'Some of the projects we’re working on. ¹ and / or impactful.',
    projects: [
      {
        logo: '/assets/logos/bottlerLogo.png',
        title: 'Bottler',
        desc: 'A sustainable way for creators to sustain their passion by receiving recurring donations every month.',
        tags: ['Design', 'System', 'Payments'],
        link: 'https://bottler.community/',
        linkText: 'visit website',
        buttonType: 'banan'
      },
      {
        logo: '/assets/logos/cloudgardenLogo.png',
        title: 'Cloud Garden',
        desc: 'Maximize indoor comfort and health with a smart air sensor.',
        tags: ['System', 'IoT', 'Analytics'],
        link: 'https://cloudgarden.nl/',
        linkText: 'visit website',
        buttonType: 'banan'
      },
      {
        logo: '/assets/logos/covidLogo.png',
        title: 'COVID19 Reporting',
        desc: 'Reporting system and generation of ready-to-fly certificates for PCR and antigen tests.',
        tags: ['System', 'Design', 'Automation'],
        link: '',
        linkText: '*secret*',
        buttonType: 'text'
      },
      {
        logo: '/assets/logos/mimerLogo.png',
        title: 'Mimer',
        desc: 'Automatic analysis and extraction of sustainability data from annual financial reports.',
        tags: ['System', 'Machine Learning'],
        link: 'https://mimer.sunlabs.se/',
        linkText: 'visit website',
        buttonType: 'banan'
      },
      {
        logo: '/assets/logos/walter.png',
        title: 'Walter',
        desc: 'Vulnerability scanner for web applications to find potential security risks.',
        tags: ['Cybersecurity', 'Design'],
        link: 'https://walter.sunlabs.se/',
        linkText: 'Visit website',
        buttonType: 'banan'
      }
    ],
    opensourceTitle: 'Open Source Projects',
    opensourceSubtitle: 'We believe in an open business climate where laid off projects may be of use by someone else.',
    opensource: [
      {
        logo: '/assets/logos/suneyeLogo.png',
        title: 'Weather Analysis by Images',
        desc: 'The backbone of our society, almost. Nominated by Royal Swedish Academy of Engineering Sciences as a hell of a party.',
        tags: ['Image Recognition'],
        link: 'https://github.com/sun-labs/sun-eye',
        linkText: 'View source',
        buttonType: 'banan'
      },
      {
        logo: '/assets/logos/yameruLogo.png',
        title: 'Yameru · やめる',
        desc: 'Secure your Mac from hot plug attacks and theft at public spaces.',
        tags: ['App', 'Security', 'Design'],
        link: 'https://apps.apple.com/us/app/yameru/id1511487314',
        linkText: 'View source',
        buttonType: 'app'
      },
      {
        logo: '/assets/logos/ediLogo.png',
        title: 'EDIClue',
        desc: 'Convert EDI messages to JSON. We do not understand how it works, but it does.',
        tags: ['Energy', 'EDI', 'Tool'],
        link: 'https://ediclue.sunlabs.se/',
        linkText: 'Visit website',
        buttonType: 'banan'
      },
      {
        logo: '/assets/logos/visualizationLogo.png',
        title: 'Visualization Projects',
        desc: 'Real time visualization of high resolution data, especially made for Solar Cell Facilities.',
        tags: ['Design', 'Energy', 'BI'],
        link: 'https://github.com/sun-labs',
        linkText: 'View source',
        buttonType: 'banan'
      }
    ],
    matrix: {
      title: 'Into the Matrix',
      subtitle: 'The following tech palette is our mother tongue.',
      tags: [
        'javascript',
        'apps',
        'react',
        'design',
        'stripe',
        'sql',
        'nosql',
        'prototyping',
        'machine learning',
        'docker',
        'cloud'
      ]
    },
    footer: {
      title: 'Are you fun?',
      subtitle: 'We’re looking for fun projects driven by  fun people. Know one?  Hit us up!',
      content: [
        { title: 'Email', content: [{ title: 'hello @ sunlabs.se', link: 'mailto:hello@sunlabs.se' }] },
        { title: 'Company', content: [{ title: 'Sun Labs Nordic AB' }, { title: '559120-7823' }, { title: 'SE559120782301' }] },
        {
          title: 'Our Fan Club',
          content: [
            { title: 'LinkedIn', link: 'https://www.linkedin.com/company/sunlabsnordic/' },
            { title: 'Instagram', link: 'https://www.instagram.com/sunlabsnordic/' },
            { title: 'Github', link: 'https://github.com/sun-labs' },
            { title: 'Bottler', link: 'https://bottler.community/@sun-labs' }
          ]
        }

      ]
    }

  }
}

export default translations
