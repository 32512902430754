import styled from 'styled-components'

const Emoji = styled.span`
  font-size: ${props => props.size || '36px'};
  animation-name: wave-animation;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
  transform-origin: 70% 70%;
  display: inline-block;
  user-select: none;

  @keyframes wave-animation {
      0% { transform: rotate( 0.0deg) }
    10% { transform: rotate(18.0deg) }
    20% { transform: rotate(-12.0deg) }
    30% { transform: rotate(18.0deg) }
    40% { transform: rotate(-6.0deg) }
    50% { transform: rotate(14.0deg) }
    60% { transform: rotate( 0.0deg) }
    100% { transform: rotate( 0.0deg) }
  }
`
const SmallButton = (props) => {
  const { size } = props
  return (
    <div>
      <Emoji size={size} role='img' aria-label='hello'>👋</Emoji>
    </div>
  )
}

export default SmallButton
