import { useEffect, useState, useCallback } from 'react'
import { useSpring, animated, interpolate } from 'react-spring'
import styled from 'styled-components'
import { useApp } from '../hooks'

import { colors } from '../theme'

const BackgroundWrap = styled(animated.div)`
  background-color: ${props => props.color || props.theme?.primaryBg};
  height: 100%;
  width: 100%;
`

const Background = (props) => {
  const [{ st }, set] = useSpring(() => ({ st: 0 }))
  const { positions, colors } = useApp()
  const color = st.interpolate(positions, colors)
  const handleScroll = useCallback((e) => {
    // setScrollY(window.scrollY)
    set({ st: window.scrollY })
  }, [])
  useEffect(() => {
    document.addEventListener('scroll', handleScroll)
    return () => document.removeEventListener('scroll', handleScroll)
  }, [])
  return (
    <BackgroundWrap style={{ backgroundColor: color }} />
  )
}

export default Background
