import { ThemeProvider, createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`

  body {
    margin: 0;
    padding: 0;
    font-family: 'mulish', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

`

export const colors = {
  yellow: '#FFE601',
  darkGrey: '#BBB',
  blackTwo: 'rgba(0,0,0,0.5)',
  gray: '#DADADA',
  liteGray: '#F5F5F5',
  white: '#FFFFFF',
  black: '#222222',
  green: '#81DC26',
  blue: '#1DA0FF'
}

export const themeStandard = {
  primaryBg: colors.yellow,
  contentBg: colors.white,
  primaryTextColor: colors.black,
  primaryBorderColor: colors.gray,
  matrixTagBorderColor: '#444444',
  logoBackground: colors.liteGray,
  subtitleColor: colors.blackTwo,
  whisperColor: colors.blackTwo,
  linkColor: colors.black,
  sectionColors: {
    intro: {
      backgroundColor: colors.yellow
    },
    projects: {
      backgroundColor: colors.green
    },
    openSource: {
      backgroundColor: colors.blue
    },
    matrix: {
      backgroundColor: 'black'
    },
    footer: {
      backgroundColor: colors.yellow
    }
  }
}

export const themeDark = {
  ...themeStandard,
  primaryBg: colors.yellow,
  linkColor: colors.white,
  contentBg: colors.black,
  subtitleColor: colors.darkGrey,
  whisperColor: colors.darkGrey,
  primaryTextColor: colors.white,
  primaryBorderColor: '#444444',
  logoBackground: colors.liteGray
}

const SunTheme = (props) => {
  const { children, theme } = props
  return (
    <ThemeProvider theme={theme === 'dark' ? themeDark : themeStandard}>
      {children}
    </ThemeProvider>
  )
}

export default SunTheme
export const DefaultStyle = GlobalStyle
