import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useSprings, animated } from 'react-spring'
import { useInView } from 'react-intersection-observer'
import { useApp } from '../hooks'

import {
  Title as _Title,
  Text as _Text
} from '../components/Text'

import _Tag from '../components/Tag'

import Section from '../layouts/Section'
import { useEffect, useLayoutEffect, createRef } from 'react'
import { colors } from '../theme'

const TagWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 2rem;
  width: 320px;
  flex-wrap: wrap;
  & > *{
    margin: 0.25rem;
  }
`

const Tag = styled(_Tag)`
  color: rgb(0,255,0);
  border-color: ${props => props.theme.matrixTagBorderColor};
`

const Background = styled(Section)`
  background-color: black;
  color: rgb(0,255,0);
  display: flex;
  align-items: center;
  justify-content: center;
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Title = styled(_Title)`
  color: rgb(0, 255, 0);
  margin-bottom: 0.5rem;
`

const Text = styled(_Text)`
  color: rgb(0, 255, 0);
  max-width: 320px;
  opacity: 0.5;
`

const Matrix = (props) => {
  const { setSection } = useApp()
  const backgroundRef = createRef()
  const { t } = useTranslation()
  const tags = t('matrix.tags', { returnObjects: true })
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: '0px 0px',
    delay: 200
  })

  const springs = useSprings(tags.length, tags.map((item, i) =>
    ({
      from: {
        opacity: 0
      },
      opacity: inView ? 1 : 0,
      delay: (100 * i)
    })
  ))

  useLayoutEffect(() => {
    setSection('matrix', {
      color: 'black',
      scrollTop: backgroundRef.current.offsetTop
    })
  }, [])

  return (
    <Background ref={backgroundRef} fill>
      <Header>
        <Title>{t('matrix.title')}</Title>
        <Text>{t('matrix.subtitle')}</Text>
        <TagWrapper ref={ref}>
          {tags.map((tag, i) => {
            const animation = inView ? springs[i] : springs[0]
            return (
              <animated.div style={animation} key={tag}>
                <Tag value={tag} />
              </animated.div>
            )
          }
          )}
        </TagWrapper>
      </Header>
    </Background>
  )
}

export default Matrix
