
import styled from 'styled-components'
import { device } from '../devices'
import { useSprings, animated } from 'react-spring'
import { useInView } from 'react-intersection-observer'

import ProjectCard from './ProjectCard'

const Card = styled(animated.div)`
  display: flex;
  justify-content: center;
  @media ${device.tablet} {
    border: 1px solid ${props => props.borderColor || props.theme?.primaryBorderColor};
    border-radius: 10px;
  }
`

const LargeSpacer = styled.div`
  margin-top: 3rem;
`

const ProjectWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  padding: 2em;
  @media ${device.tablet} {
    grid-template-columns: 1fr 1fr;
    padding: 5em;
    column-gap: 5%;
    row-gap: 2%;
  }
`

const ProjectsWrap = ({ item }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.3
  })

  const springs = useSprings(item.length, item.map((item, i) => {
    return {
      from: {
        opacity: 0
      },
      opacity: inView ? 1 : 0,
      delay: (200 * i)
    }
  }
  ))
  return (
    <ProjectWrap ref={ref}>
      {item && item.map((item, i) => {
        const animation = inView ? springs[i] : springs[0]
        return (
          <Card style={animation} key={i}>
            <ProjectCard {...item} />
            <LargeSpacer />
          </Card>
        )
      })}
    </ProjectWrap>
  )
}
export default ProjectsWrap
