import { useState } from 'react'
import { Text } from './Text'

const HEART_EMOJI = 1
const HEART_TEXT = 2

const FromUppsala = () => {
  const [heartType, setHeartType] = useState(HEART_EMOJI)
  const handleHover = () => {
    const newType = heartType === HEART_EMOJI
      ? HEART_TEXT
      : HEART_EMOJI
    setHeartType(newType)
  }
  const heart = heartType === HEART_EMOJI
    ? '❤️'
    : '<3'
  return (
    <Text
      onMouseEnter={handleHover}
      onMouseLeave={handleHover}
      color='black'
      style={{ letterSpacing: '0.5px' }}
    >
      With <span alt='lovelovelove' style={{ color: '#FF3434' }}>{heart}</span> from Uppsala
    </Text>
  )
}

export default FromUppsala
