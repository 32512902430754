
import { useLayoutEffect, createRef } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import ContentWrap from '../components/ContentWrap'
import { Title, Subtitle } from '../components/Text'
import ProjectsWrap from '../components/ProjectsWrap'

import { useApp } from '../hooks'
import { colors } from '../theme'

const LargeSpacer = styled.div`
  margin-top: 3rem;
`

const Projects = () => {
  const ref = createRef()
  const { setSection } = useApp()
  const [t] = useTranslation()
  const projects = t('projects', { returnObjects: true })
  useLayoutEffect(() => {
    setSection('projects', {
      color: colors.green,
      scrollTop: ref.current.offsetTop
    })
  }, [])
  return (
    <>
      <ContentWrap ref={ref}>
        <LargeSpacer />
        <Title>
          {t('projectsTitle')}
        </Title>
        <Subtitle size='1rem'>
          {t('projectsSubtitle')}
        </Subtitle>
      </ContentWrap>
      <ProjectsWrap item={projects} />
    </>
  )
}

export default Projects
