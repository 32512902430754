import styled from 'styled-components'
import { useSpring, animated } from 'react-spring'
import { useTranslation } from 'react-i18next'
import { useInView } from 'react-intersection-observer'

import { createRef, useLayoutEffect } from 'react'
import { useApp } from '../hooks'
import { colors } from '../theme'
import ContentWrap from '../components/ContentWrap'
import { Text, Title, Link, Subtitle } from '../components/Text'

const LargeSpacer = styled.div`
  margin-top: 3rem;
`

const Spacer = styled.div`
  margin-top: 1rem;
`

const Row = styled.div`
  display: flex;
  justify-content: space-around;
  padding-bottom: 1rem;
`

const Column = styled.div`
  flex: 1;
  text-align: left;
`

const Wrap = styled.div`
  padding-bottom: 5rem;
`

const Footer = (props) => {
  const [t] = useTranslation()
  const { setSection } = useApp()
  const wrapperRef = createRef()
  const items = t('footer.content', { returnObjects: true })
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: '-100px 0px'
  })
  const { x } = useSpring({ x: inView ? 1 : 0, config: { duration: 1000 } })
  useLayoutEffect(() => {
    setSection('footer', {
      color: colors.yellow,
      scrollTop: wrapperRef.current.offsetTop
    })
  }, [])
  return (
    <Wrap ref={wrapperRef}>
      <animated.div
        ref={ref}
        style={{
          opacity: x.interpolate({ range: [0, 1], output: [0.3, 1] })
        }}
      >
        <ContentWrap width='350px'>
          <LargeSpacer />
          <Spacer />
          <Title>{t('footer.title')}</Title>
          <Spacer />
          <Subtitle size='1rem'>
            {t('footer.subtitle')}
          </Subtitle>
          <Spacer />
        </ContentWrap>
        <LargeSpacer />
        <ContentWrap width='350px'>
          {items && items.map((item, i) => {
            const { content = [], title } = item
            return (
              <Row key={i}>
                <Column>
                  <Text color={colors.darkGrey}>{title}</Text>
                </Column>
                <Column>
                  {content.map((children, index) => {
                    const { title, link } = children
                    return (
                      <div key={index}>
                        {link
                          ? <Link rel='noopener noreferrer' target='_blank' href={link}>{title}</Link>
                          : <Text>{title}</Text>}

                      </div>
                    )
                  })}
                </Column>
              </Row>
            )
          })}
        </ContentWrap>
        <LargeSpacer />
      </animated.div>
    </Wrap>
  )
}

export default Footer
