import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useLayoutEffect, createRef } from 'react'

import { useApp } from '../hooks'
import { colors } from '../theme'

import ContentWrap from '../components/ContentWrap'
import LinkButton from '../components/LinkButton'
import { Title, Subtitle } from '../components/Text'
import ProjectsWrap from '../components/ProjectsWrap'

const LargeSpacer = styled.div`
  margin-top: ${props => props.space || '3rem'};
`

const ButtonContainer = styled.div`
  max-width: 200px;
  margin: 0 auto;
`

const OpenSource = () => {
  const { setSection } = useApp()
  const [t] = useTranslation()
  const ref = createRef()
  const openSource = t('opensource', { returnObjects: true })
  useLayoutEffect(() => {
    setSection('openSource', {
      color: colors.blue,
      scrollTop: ref.current.offsetTop
    })
  }, [])
  return (
    <>
      <ContentWrap ref={ref}>
        <LargeSpacer />
        <Title>
          {t('opensourceTitle')}
        </Title>
        <Subtitle size='1rem'>
          {t('opensourceSubtitle')}
        </Subtitle>
        <LargeSpacer space='1rem' />
        <ButtonContainer>
          <LinkButton
            linkText={t('lblgithub')}
            link='https://github.com/sun-labs'
          />
        </ButtonContainer>
      </ContentWrap>
      <ProjectsWrap item={openSource} />
      <LargeSpacer />
    </>
  )
}

export default OpenSource
