import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { colors } from '../theme'

import Link from '../assets/icons/link.svg'

const Text = styled.span`
  color: ${props => props.color || props.theme?.linkColor};
  line-height: 150%;
`

const Clickable = styled.div`
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.25s ease-out;
  &:hover {
    opacity: 0.75;
  }
`

const BadgeImage = styled.img`
  height: 35px;
`

const ButtonContainer = styled.a`
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem 0.75rem;
  transition: 0.2s border-color ease-out;
  border: ${props => props.noBorder
    ? 'none'
    : `1px solid ${props.borderColor || props.theme?.primaryBorderColor}`};
  border-radius: 4px;

  &:hover {
    border-color: ${colors.blue};
  }
`

const Icon = styled.img`
  padding-left: 0.5rem;
  fill: ${props => props.borderColor || props.theme?.primaryBorderColor};
`

const Button = (props) => {
  const {
    onClickEvent,
    linkText,
    buttonType,
    children,
    link
  } = props

  const [t] = useTranslation()

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank')
    if (newWindow) newWindow.opener = null
  }

  const onClickEventHandler = (e) => {
    if (onClickEvent) {
      onClickEvent(e)
    } else {
      openInNewTab(link)
    }
  }

  return (
    <>
      {
      (buttonType === 'text' &&
        <ButtonContainer noBorder>
          <Text color={colors.darkGrey}>{linkText}</Text>
        </ButtonContainer>) ||
      (buttonType === 'app' &&
        <Clickable style={{ display: 'flex' }}>
          <a href={link} target='_blank' rel='noopener noreferrer'>
            <BadgeImage src='/assets/badges/mac-app-store.svg' />
          </a>
        </Clickable>
      ) || (
        <Clickable>
          <ButtonContainer href={link} target='_blank' rel='noopener noreferrer'>
            <Text>{linkText || children || t('lblbutton')}</Text>
            <Icon src={Link} />
          </ButtonContainer>
        </Clickable>
      )
}
    </>
  )
}

export default Button
