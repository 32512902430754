import { useContext, useLayoutEffect } from 'react'
import context from './context'

export const useApp = () => {
  const state = useContext(context.Context)
  return state
}

export const useBackgroundScroll = (sectionId, section = {}) => {
  const { color, scrollTop } = section
  const { setSection } = useApp()
  useLayoutEffect(() => {
    setSection('matrix', {
      color,
      scrollTop
    })
  }, [])
}
