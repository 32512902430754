import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Theme, { DefaultStyle } from './theme';
import context from './context';
import './assets.css';
import './translations';
import './hacks';
import { Helmet } from 'react-helmet';

const { Provider } = context;

const Main = () => {
  const [theme, setTheme] = useState();
  const getMql = () =>
    window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)');

  const getBrowserTheme = () => {
    const mql = getMql();
    return mql && mql.matches ? 'dark' : 'light';
  };

  useEffect(() => {
    const browserMode = getBrowserTheme();
    const htmlBackground = browserMode === 'dark' ? 'black' : 'white';
    document.documentElement.style.backgroundColor = htmlBackground;
    setTheme(browserMode);
  }, []);

  return (
    <React.StrictMode>
      <Provider>
        <DefaultStyle />
        <Theme theme={theme}>
          <Helmet />
          <App />
        </Theme>
      </Provider>
    </React.StrictMode>
  );
};

ReactDOM.render(<Main />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
