import styled from 'styled-components'

const Wrap = styled.div`
  max-width: ${props => props.width || '500px'};
  display: flex;
  flex: 1;
  flex-direction: column;
  text-align: center;
  padding: 0 2em;
  margin: 0 auto;
`

export default Wrap
