import styled from 'styled-components'

export const Text = styled.span`
  text-align: unset;
  line-height: 150%;
  color: ${props => props.color || props.theme?.primaryTextColor};
  font-size: ${props => props.size || '1rem'};
`

export const Title = styled.h2`
  text-align: unset;
  line-height: 150%;
  margin: 0;
  margin-bottom: 0.5rem;
  color: ${props => props.color || props.theme?.primaryTextColor};
  font-size: ${props => props.size || '1rem'};
  font-weight: 500;
  font-size: 1.5rem;
`

export const Subtitle = styled(Text)`
  color: ${props => props.color || props.theme?.subtitleColor};
`

export const Link = styled.a`
  text-align: unset;
  line-height: 200%;
  color: ${props => props.color || props.theme?.primaryTextColor};
  font-size: ${props => props.size || '1rem'};
  text-decoration: none;
`
