import styled from 'styled-components'
import { useSpring, animated } from 'react-spring'
import TreeImg from '../assets/illustrations/tree.png'
import YameruImg from '../assets/illustrations/yameru.png'

import { device } from '../devices'

const Wrap = styled.div`
  overflow: hidden;
  position: relative;
  height: 400px;
  width: 100%;
  @media ${device.mobileL} {
    height: 500px;
  }
`

const TreeWrap = styled(animated.img)`
  position: absolute;
  height: 400px;
  margin-top: -50px;
  @media ${device.mobileL} {
    height: 600px;
    margin-top: -115px;
  }
`

const YameruWrap = styled(animated.img)`
    display: none;
  @media ${device.tablet} {
    display: block;
    position: absolute;
    height: 500px;
  }
`

const Tree = () => {
  const propsTree = useSpring({
    transform: 'rotateZ(-45deg)',
    right: '-50px',
    from: {
      transform: 'rotateZ(0deg)',
      right: '-400px'
    }
  })

  const propsYameru = useSpring({
    left: '-225px',
    from: {
      left: '-450px'
    },
    delay: 350
  })

  return (
    <Wrap>
      <TreeWrap src={TreeImg} alt='tree' style={propsTree} />
      <YameruWrap src={YameruImg} alt='yameru' style={propsYameru} />
    </Wrap>
  )
}

export default Tree
