import styled from 'styled-components'
const TagBase = styled.div`
  border-width: 1px;
  border-color: ${props => props.borderColor || 'lightgray'};
  border-style: solid;
  border-radius: 4px;
  padding: 0.25rem 0.5rem;
`

const Tag = (props) => {
  const { value, className = '' } = props
  return (
    <TagBase className={className}>
      {value}
    </TagBase>
  )
}

export default Tag
