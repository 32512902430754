import { useState, createContext } from 'react'

export const Context = createContext({})

const ORDER = [
  'intro',
  'projects',
  'openSource',
  'matrix',
  'footer'
]

const Provider = (props) => {
  const { children } = props

  const [sections, setSections] = useState({})

  const orderedSections = ORDER.map((key) => sections[key])
  const SOLID_PADDING = 300
  const positions = orderedSections.map((section) => {
    const scrollTop = section?.scrollTop
    return [scrollTop, scrollTop + SOLID_PADDING] // to prolong solid state of color
  }).reduce((acc, cur) => [...acc, ...cur])
  const colors = orderedSections.map((section) => {
    const color = section?.color
    return [color, color] // to prolong solid state of color
  }).reduce((acc, cur) => [...acc, ...cur])
  const setSection = (sectionId, { color, scrollTop }) => {
    setSections(sections => ({
      ...sections,
      [sectionId]: {
        color,
        scrollTop
      }
    }))
  }

  const value = {
    positions,
    colors,
    setSection
  }

  return (
    <Context.Provider value={value}>
      {children}
    </Context.Provider>
  )
}

const Root = {
  Context,
  Provider
}

export default Root
