import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import en from './assets/translations/en'
import sv from './assets/translations/sv'

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en,
      sv
    },
    lng: 'sv',
    fallbackLng: 'en'
  })

export default i18n
