import styled from 'styled-components'
import { device } from './devices'

import Header from './components/Header'
import FromUppsala from './components/FromUppsala'
import Background from './components/Background'

import Intro from './pages/Intro'
import Projects from './pages/Projects'
import Matrix from './pages/Matrix'
import OpenSource from './pages/OpenSource'
import Footer from './pages/Footer'

const FixedPos = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const MaxWrap = styled.div`
  max-width: 960px;
  margin: 0 auto;
  z-index: 10;
  position: relative;
  padding-left: 2px;
  padding-right: 2px;
  @media ${device.mobileL} {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`

const CardWrap = styled.div`
  background: ${props => props.color || props.theme?.contentBg};
  border-radius: 2em 2em;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`

const AppWrapper = styled.section`
`

const Spacer = styled.div`
  margin: ${props => props.space || '2em 0'};
  display: flex;
  justify-content: center;
`

function App () {
  return (
    <AppWrapper>
      <FixedPos>
        <Background />
      </FixedPos>
      <MaxWrap>
        <Header />
        <CardWrap>
          <Intro />
          <Projects />
          <OpenSource />
          <Matrix />
          <Footer />
        </CardWrap>
        <Spacer space='5rem 0'>
          <FromUppsala />
        </Spacer>
      </MaxWrap>
    </AppWrapper>
  )
}

export default App
