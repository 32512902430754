import styled, { css } from 'styled-components'

const Section = styled.section`
  padding: 1rem;
  ${props => props.fill && css`
    height: 100vh;
  `}
`

export default Section
