import styled from 'styled-components'
import { useSpring, animated } from 'react-spring'
import { useTranslation } from 'react-i18next'
import { useLayoutEffect } from 'react'

import EmojiHand from '../components/EmojiHand'
import ContentWrap from '../components/ContentWrap'
import Animations from '../components/IntroAnimations'
import { Text, Title, Subtitle } from '../components/Text'
import { colors } from '../theme'
import { useApp } from '../hooks'

const LargeSpacer = styled.div`
  margin-top: 3rem;
`

const Spacer = styled.div`
  margin-top: 1rem;
`

const Whisper = styled(Text)`
color: ${props => props.theme?.whisperColor || 'red'};
`

const Intro = (props) => {
  const { setSection } = useApp()
  const [t] = useTranslation()
  const { x } = useSpring({ from: { x: 0 }, x: 1, config: { duration: 1000 } })
  useLayoutEffect(() => {
    setSection('intro', {
      color: colors.yellow,
      scrollTop: 0
    })
  }, [])
  return (
    <>
      <ContentWrap>
        <LargeSpacer />
        <LargeSpacer />
        <animated.div
          style={{
            opacity: x.interpolate({ range: [0, 1], output: [0.3, 1] })
          }}
        >
          <EmojiHand />
          <Spacer />
          <Title>{t('introTitle')}</Title>
          <Spacer />
          <Subtitle size='1rem'>
            {t('introSubtitle')}
          </Subtitle>
        </animated.div>
        <animated.div
          style={{
            opacity: x.interpolate({ range: [0, 1], output: [0.3, 1] })
          }}
        >
          <Spacer />
          <Whisper size='1rem'>
            {t('introExtra')}
          </Whisper>
        </animated.div>
      </ContentWrap>
      <Animations />
    </>
  )
}

export default Intro
